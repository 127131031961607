import { RMText } from '@/components/RMText/RMText';

import { ClickableText, CloseButton, StyledPageBanner } from './RMPageBanner.styles';

export interface RMPageBannerProps {
  message: string;
  action?: string | null;
  style?: 'info' | 'danger';
  showClose?: boolean;
  onAction?: () => void;
  onClose?: () => void;
}

export function RMPageBanner({
  message,
  action,
  style = 'info',
  showClose = false,
  onAction,
  onClose,
}: RMPageBannerProps) {
  return (
    <StyledPageBanner data-style={style}>
      <RMText type="sans" size="s" bold color="on-surface-primary" align="center">
        {message}
        {action && (
          <>
            &nbsp;
            <ClickableText type="sans" size="s" bold color="on-surface-primary" underline onClick={onAction}>
              {action}
            </ClickableText>
          </>
        )}
      </RMText>
      {showClose && <CloseButton onClick={onClose} />}
    </StyledPageBanner>
  );
}
